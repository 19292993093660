<script>
  import { ApiError } from '@/api'
  import accountApi from '@/api/account'

  export default {
    data() {
      return {
        formRef: null,
        resetPasswordSuccess: false,
        username: null,
        showCheckmark: true,
      }
    },
    watch: {
      resetPasswordSuccess() {
        if (this.resetPasswordSuccess) {
          setTimeout(() => {
            this.showCheckmark = false
          }, 3000)
        }
      },
    },
    methods: {
      async submit() {
        try {
          await this.formRef.process(accountApi.resetPassword({ username: this.username }))
          this.resetPasswordSuccess = true
        } catch (error) {
          if (error instanceof ApiError) {
            // Don't need to do anything with expected (API) errors, so just returning
            return
          }
          return Promise.reject(error)
        }
      },
    },
  }
</script>

<template>
  <section class="py-15">
    <BaseContainer max-width="sm">
      <BaseRichText>
        <BaseHeading size="h1">Reset Password</BaseHeading>
        <p>Reset your Alltrue account password</p>
      </BaseRichText>

      <BaseForm
        v-slot="{ inputsEnabled, submitEnabled, errorMessage }"
        v-ref="formRef"
        :spinner="showCheckmark"
        class="mt-10 text-left"
        @submit="submit"
      >
        <p
          v-if="resetPasswordSuccess"
          class="mb-6 text-primary-dark"
          data-cy="reset-password-success"
        >
          An email has been sent to <strong>{{ username }}</strong> with a link to reset your
          password.
        </p>

        <BasePanelWarning v-if="errorMessage" class="mb-6">
          {{ errorMessage }}
        </BasePanelWarning>

        <BaseStack>
          <BaseInput
            v-model="username"
            type="email"
            name="username"
            :disabled="!inputsEnabled"
            required
          >
            Email
          </BaseInput>
        </BaseStack>

        <BaseButton type="submit" :disabled="!submitEnabled" class="mt-6">
          <slot name="buttonLabel">Reset</slot>
        </BaseButton>
      </BaseForm>

      <BaseRichText class="mt-8">
        <p>
          <BaseLinkStyled :to="{ name: 'AccountLogin' }" inline>
            <template v-slot:icon>
              <IconChevronLeft />
            </template>
            <template v-slot>Login</template>
          </BaseLinkStyled>
        </p>
      </BaseRichText>
    </BaseContainer>
  </section>
</template>
